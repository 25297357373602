<template>
    <div>

        <b-card no-body class="mb-5">
            <b-card-header header-tab="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('advanced_filter')}}
                </b-button>
            </b-card-header>

            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row mb-5">

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="customer_id">{{$t('daily_brief_report_invoices.customer')}}</label>
                                <multiselect v-model="customer"
                                    :placeholder="$t('daily_brief_report_invoices.customer')"
                                    label="fullname"
                                    track-by="id"
                                    :options="customers"
                                    :multiple="false"
                                    :taggable="true"
                                    :show-labels="false"
                                    :show-no-options="false"
                                    :show-no-results="false">
                                </multiselect>
                            </div>

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="from_date">{{$t('daily_brief_report_invoices.from_date')}}</label>
                                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                            </div>
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="to_date">{{$t('daily_brief_report_invoices.to_date')}}</label>
                                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                            </div>

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label>{{$t('daily_brief_report_invoices.added_by')}}</label>
                                <multiselect v-model="user"
                                    :placeholder="$t('daily_brief_report_invoices.added_by')"
                                    label="name"
                                    track-by="id"
                                    :options="users"
                                    :multiple="false"
                                    :taggable="true"
                                    :show-labels="false"
                                    :show-no-options="false"
                                    :show-no-results="false">
                                </multiselect>
                            </div>
                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="f_status">{{$t('status')}}</label>
                                <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                                    <option value="">{{$t('all')}}</option>
                                    <option v-for="(row, index) in status_list" :value="row.id" :key="'status'+index">{{ row.title }}</option>
                                </select>
                            </div>


                            <div class="form-group d-inline-flex col-md-6 mt-10">
                                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                    {{$t('display_report')}}
                                </button>
                                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                                    <i class="fas fa-trash-restore"></i> {{$t('reset_search')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>
        
        <div class="card card-custom">
            <div class="card-body">
                <b-tabs content-class="mt-3">
                    <b-tab active :title="$t('daily_brief_report_invoices.summary')">

                        <div class="row mb-10">
                            <div class="col-md-12 mt-10">
                                <h4 class="text-center">{{ $t('daily_brief_report_invoices.daily_brief_report_invoices')}}</h4>
                                <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from')}} {{filters.from_date}} {{ $t('to')}} {{filters.to_date}}</h5>
                            </div>
                            <div class="col-md-6 mt-10" v-if="line_invoice">
                                <LineChart :data-line-invoice="line_invoice" :currency-name="currency_name"/>
                            </div>
                            <div class="col-md-6 mt-10" v-if="paid_unpaid">
                                <DoughnutChart :data-paid-unpaid="paid_unpaid" :currency-name="currency_name"/>
                            </div>
                        </div>
                        <div class="row mb-10">
                            <div class="col-12" v-if="data_report">
                                <div class="table-responsive">
                                    <div class="row justify-content-end p-4">
                                        <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                                            <download-excel
                                                    class="dropdown-item"
                                                    :fetch="dataReportFn"
                                                    :fields="json_fields_summary"
                                                    :name="'summary_report.xls'">
                                                <i class="la la-file-excel"></i>{{$t('excel')}}
                                            </download-excel>

                                            <!-- <button id="btnExport" class="dropdown-item" @click="fnCSVReport">
                                                <span><i class="la la-file-excel"></i> <span>CSV</span></span>
                                            </button> -->

                                            <!-- <div @click="exportReport('csv', 'revenue_invoice', $t('revenue_invoice'))" class="dropdown-item">
                                                <i class="fa fa-file-o"></i>
                                                Export CSV
                                            </div> -->
                                        </b-dropdown>
                                    </div>
                                    <table class="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th>{{$t('daily_brief_report_invoices.date')}}</th>
                                            <th>{{$t('daily_brief_report_invoices.paid')}} ({{currency_name}})</th>
                                            <th>{{$t('daily_brief_report_invoices.unpaid')}} ({{currency_name}})</th>
                                            <th>{{$t('daily_brief_report_invoices.refund')}} ({{currency_name}})</th>
                                            <th>{{$t('daily_brief_report_invoices.total')}} ({{currency_name}})</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(row, index) in data_report" :key="'dataReport'+index">
                                            <td>{{row.label}}</td>
                                            <td>{{row.paid}}</td>
                                            <td>{{row.unpaid}}</td>
                                            <td>{{row.refund}}</td>
                                            <td>{{row.total}}</td>
                                        </tr>

                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <td colspan="4">{{$t('daily_brief_report_invoices.total')}}</td>
                                            <th>{{total_sum}}</th>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </b-tab>
                    <b-tab :title="$t('daily_brief_report_invoices.details')">

                        <div class="row mb-10">
                            <div class="col-md-12 mt-10">
                                <h4 class="text-center">{{ $t('daily_brief_report_invoices.daily_brief_report_invoices')}}</h4>
                                <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from')}} {{filters.from_date}} {{ $t('to')}} {{filters.to_date}}</h5>
                            </div>
                            <div class="col-md-6 mt-10" v-if="line_invoice_details">
                                <LineChart :data-line-invoice="line_invoice_details" :currency-name="currency_name"/>
                            </div>
                            <div class="col-md-6 mt-10" v-if="paid_unpaid_details">
                                <DoughnutChart :data-paid-unpaid="paid_unpaid_details" :currency-name="currency_name"/>
                            </div>
                        </div>
                        <div class="row mb-10">
                            <div class="col-12" v-if="data_report_details">
                                <div class="table-responsive">
                                    <div class="row justify-content-end p-4">
                                        <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                                            <download-excel
                                                    class="dropdown-item"
                                                    :fetch="revenueInvoiceExport"
                                                    :fields="json_fields"
                                                    :name="'revenue_invoice.xls'">
                                                <i class="la la-file-excel"></i>{{ $t('revenue_invoice')}}
                                            </download-excel>
                                            <download-excel
                                                    class="dropdown-item"
                                                    :fetch="salesInvoiceExport"
                                                    :fields="json_fields_sales_invoice"
                                                    :name="'sales_invoice.xls'">
                                                <i class="la la-file-excel"></i>{{ $t('sales_invoices.sales_invoices')}}
                                            </download-excel>
                                            <!--  <button id="btnExport" class="dropdown-item" @click="fnExcelReport">
                                                 <span><i class="la la-file-excel"></i> <span>{{$t('excel')}}</span></span>
                                             </button> -->
                                            <!-- <button id="btnExport" class="dropdown-item" @click="excelSalesInvoice">
                                                <span><i class="la la-file-excel"></i> <span>{{$t('sales_invoice')}}</span></span>
                                            </button> -->

                                            <!-- <button id="btnExport" class="dropdown-item" @click="fnCSVReport">
                                                <span><i class="la la-file-excel"></i> <span>CSV</span></span>
                                            </button> -->

                                            <!-- <div @click="exportReport('csv', 'revenue_invoice', $t('revenue_invoice'))" class="dropdown-item">
                                                <i class="fa fa-file-o"></i>
                                                Export CSV
                                            </div> -->
                                        </b-dropdown>
                                    </div>
                                    <table class="table table-bordered" id="revenue_invoice">
                                        <thead>
                                        <tr>
                                            <th>{{$t('daily_brief_report_invoices.number')}}</th>
                                            <th>{{$t('daily_brief_report_invoices.customer')}}</th>
                                            <th>{{$t('daily_brief_report_invoices.employee')}}</th>
                                            <th>{{$t('daily_brief_report_invoices.paid')}} ({{currency_name}})</th>
                                            <th>{{$t('daily_brief_report_invoices.unpaid')}} ({{currency_name}})</th>
                                            <th>{{$t('daily_brief_report_invoices.refund')}} ({{currency_name}})</th>
                                            <th>{{$t('daily_brief_report_invoices.total')}} ({{currency_name}})</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <template v-for="(row, index) in data_report_details">
                                            <tr :key="'a'+index">
                                                <td colspan="7">{{row.label}}</td>
                                            </tr>
                                            <tr v-for="(row2, index2) in row.details" :key="'a'+getRandom()+index2">
                                                <td>{{index2 + 1}}</td>
                                                <td>{{row2.customer_name}}</td>
                                                <td>{{row2.added_by}}</td>
                                                <td>{{row2.paid}}</td>
                                                <td>{{row2.unpaid}}</td>
                                                <td>{{row2.refund}}</td>
                                                <td>{{row2.total}}</td>
                                            </tr>
                                            <tr :key="'b'+index">
                                                <td colspan="3">{{$t('daily_brief_report_invoices.total')}}</td>
                                                <td>{{row.total.sum_paid}}</td>
                                                <td>{{row.total.sum_unpaid}}</td>
                                                <td>{{row.total.sum_refund}}</td>
                                                <td>{{row.total.sum_total}}</td>
                                            </tr>
                                        </template>

                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <td colspan="6"><b>{{$t('daily_brief_report_invoices.total')}}</b></td>
                                            <th>{{details_total_sum}}</th>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </b-tab>
                    <b-tab>
                        <template #title>
                            <select name="" id="" v-model="filters.filter_by" class="custom-select border-0 pt-0 pb-0 h-auto shadow-none">
                                <option :value="null">{{$t('select_filter')}}</option>
                                <option value="weekly">{{$t('weekly')}}</option>
                                <option value="monthly">{{$t('monthly')}}</option>
                                <option value="yearly">{{$t('yearly')}}</option>
                                <option value="customer">{{$t('customer')}}</option>
                                <option value="employee">{{$t('employee')}}</option>
                                <!--                                <option value="payment_method">{{$t('payment_method')}}</option>-->
                            </select>
                        </template>
                        <div class="row mb-10">
                            <div class="col-md-12 mt-10">
                                <h4 class="text-center">{{ $t('daily_brief_report_invoices.daily_brief_report_invoices')}}</h4>
                                <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from')}} {{filters.from_date}} {{ $t('to')}} {{filters.to_date}}</h5>
                            </div>
                            <div class="col-md-6 mt-10" v-if="line_invoice_details_by_filter">
                                <LineChart :data-line-invoice="line_invoice_details_by_filter" :currency-name="currency_name"/>
                            </div>
                            <div class="col-md-6 mt-10" v-if="paid_unpaid_details_by_filter">
                                <DoughnutChart :data-paid-unpaid="paid_unpaid_details_by_filter" :currency-name="currency_name"/>
                            </div>
                        </div>
                        <div class="row mb-1 d-flex justify-content-end p-4">
                            <b-dropdown right :text="$t('export')" class="m-5">
                                <download-excel
                                        class="dropdown-item"
                                        :fetch="dataReportDetailsByFilterFn"
                                        :fields="json_fields_details_by_filter"
                                        :name="'summary_report.xls'">
                                    <i class="la la-file-excel"></i>{{$t('excel')}}
                                </download-excel>

                                <!-- <button id="btnExport" class="dropdown-item" @click="fnCSVReport">
                                    <span><i class="la la-file-excel"></i> <span>CSV</span></span>
                                </button> -->

                                <!-- <div @click="exportReport('csv', 'revenue_invoice', $t('revenue_invoice'))" class="dropdown-item">
                                    <i class="fa fa-file-o"></i>
                                    Export CSV
                                </div> -->
                            </b-dropdown>
                            <div class="col-12" v-if="data_report_details_by_filter">
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                        <tr id="myRow">
                                            <th>{{$t('daily_brief_report_invoices.number')}}</th>
                                            <th>{{$t('daily_brief_report_invoices.customer')}}</th>
                                            <th>{{$t('daily_brief_report_invoices.employee')}}</th>
                                            <th>{{$t('daily_brief_report_invoices.paid')}} ({{currency_name}})</th>
                                            <th>{{$t('daily_brief_report_invoices.unpaid')}} ({{currency_name}})</th>
                                            <th>{{$t('daily_brief_report_invoices.refund')}} ({{currency_name}})</th>
                                            <th>{{$t('daily_brief_report_invoices.total')}} ({{currency_name}})</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <template v-for="(row, index) in data_report_details_by_filter">
                                            <tr :key="'c'+index">
                                                <td colspan="7">{{row.label}}</td>
                                            </tr>
                                            <tr v-for="(row2, index2) in row.details" :key="'c'+getRandom()+index2">
                                                <td>{{index2 + 1}}</td>
                                                <td>{{row2.customer_name}}</td>
                                                <td>{{row2.added_by}}</td>
                                                <td>{{row2.paid}}</td>
                                                <td>{{row2.unpaid}}</td>
                                                <td>{{row2.refund}}</td>
                                                <td>{{row2.total}}</td>
                                            </tr>
                                            <tr :key="'d'+index">
                                                <td colspan="3">{{$t('daily_brief_report_invoices.total')}}</td>
                                                <td>{{row.total.sum_paid}}</td>
                                                <td>{{row.total.sum_unpaid}}</td>
                                                <td>{{row.total.sum_refund}}</td>
                                                <td>{{row.total.sum_total}}</td>
                                            </tr>
                                        </template>

                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <td colspan="6">{{$t('daily_brief_report_invoices.total')}}</td>
                                            <th>{{details_total_sum_by_filter}}</th>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </div>

                            </div>
                        </div>


                    </b-tab>
                </b-tabs>

            </div>
        </div>
        <!--end::customer-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import DoughnutChart from './components/Doughnut.vue';
    import LineChart from './components/Line.vue';
    // import TableToExcel from "@linways/table-to-excel";
    // import jQuery from "jquery";

    export default {
        name: "index-daily-brief-report-invoices",
        components: {DoughnutChart, LineChart},
        data() {
            return {
                mainRoute: '/dashboard',
                mainRouteDependency: 'base/dependency',

                line_invoice: [],
                paid_unpaid: [],
                data_report: [],
                currency_name: null,
                total_sum: 0,

                line_invoice_details: [],
                paid_unpaid_details: [],
                data_report_details: [],
                details_total_sum: 0,

                line_invoice_details_by_filter: [],
                paid_unpaid_details_by_filter: [],
                data_report_details_by_filter: [],
                details_total_sum_by_filter: 0,

                filters: {
                    customer_id: null,
                    from_date: null,
                    to_date: null,
                    user_id: null,
                    status: null,
                    filter_by: null,
                },
                status_list: [],
                data: [],
                customers: [],
                users: [],
                customer: null,
                user: null,
                isDisabled: true,
                json_meta: [
                    [{
                        key: "charset",
                        value: "utf-8",
                    },],
                ],

            }
        },
        watch: {
            customer: function (val) {
                if (val && val.id) {
                    this.filters.customer_id = val.id;
                } else {
                    this.filters.customer_id = null;
                }
            },
            user: function (val) {
                if (val && val.id) {
                    this.filters.user_id = val.id;
                } else {
                    this.filters.user_id = null;
                }
            },
            'filters.filter_by': function (val) {
                if (val) {
                    this.getLineChartInvoiceDetailsByFilter();
                    this.getPaidUnpaidInvoiceDetailsByFilter();
                    this.getReportInvoiceDetailsByFilter();
                }
            }
        },
        computed: {

            json_fields_summary: function () {
                let fields = {};
                fields[this.$t('revenue_invoices.date')] = 'label';
                fields[this.$t('revenue_invoices.total') + ` (${this.currency_name})`] = 'total';

                return fields;
            },
            json_fields_details_by_filter: function () {
                let fields = {};

                fields[this.$t('revenue_invoices.id')] = 'label';
                fields[this.$t('revenue_invoices.invoice_number')] = 'invoice_code';
                fields[this.$t('revenue_invoices.date')] = 'date';
                fields[this.$t('revenue_invoices.customer')] = 'customer_name';
                fields[this.$t('revenue_invoices.employee')] = 'added_by';
                fields[this.$t('revenue_invoices.payment_method')] = 'payment_method';
                fields[this.$t('revenue_invoices.payment_id')] = 'payment_id';
                fields[this.$t('revenue_invoices.total') + ` (${this.currency_name})`] = 'total';

                return fields;
            },

            // *****************************************---
            json_fields: function () {
                let fields = {};
                fields[this.$t('revenue_invoices.id')] = 'label';
                fields[this.$t('revenue_invoices.customer')] = 'customer_name';
                fields[this.$t('revenue_invoices.employee')] = 'added_by';
                fields[this.$t('revenue_invoices.paid')] = 'paid';
                fields[this.$t('revenue_invoices.unpaid')] = 'unpaid';
                fields[this.$t('revenue_invoices.refund')] = 'refund';
                fields[this.$t('revenue_invoices.total') + ` (${this.currency_name})`] = 'total';
                return fields;
            },
            json_fields_sales_invoice: function () {
                let fields = {};
                fields[this.$t('revenue_invoices.id')] = 'label';
                fields[this.$t('revenue_invoices.invoice_code')] = 'invoice_code';
                fields[this.$t('revenue_invoices.date')] = 'date';
                fields[this.$t('revenue_invoices.customer')] = 'customer_name';
                fields[this.$t('revenue_invoices.employee')] = 'added_by';
                fields[this.$t('revenue_invoices.payment_method')] = 'payment_method';
                fields[this.$t('revenue_invoices.payment_id')] = 'payment_id';
                fields[this.$t('revenue_invoices.total') + ` (${this.currency_name})`] = 'total';
                return fields;
            },

        },
        
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.daily_brief_report_invoices")}]);

            this.getCustomers();
            this.getUsers();
            this.getStatusList();
            this.getAuthUser();
        },
        methods: {
            dataReportFn() {
                let _footer = {label: this.$t('daily_brief_report_invoices.total') + ` (${this.currency_name})`, total: this.total_sum};
                let _data = [...this.data_report];
                _data.push(_footer);
                return _data;
            },
            dataReportDetailsByFilterFn() {
                let _footer = {label: this.$t('daily_brief_report_invoices.total') + ` (${this.currency_name})`, total: this.details_total_sum_by_filter};
                let _data = [];
                this.data_report_details_by_filter.forEach((row) => {
                    _data.push({
                        label: row.label,
                        invoice_code: '',
                        customer_name: '',
                        added_by: '',
                        payment_id: '',
                        payment_method: '',
                        date: '',
                        total: '',
                    });
                    row.details.forEach((sub_row) => {
                        _data.push({
                            label: sub_row.id,
                            invoice_code: sub_row.invoice_code,
                            customer_name: sub_row.customer_name,
                            added_by: sub_row.added_by,
                            payment_id: sub_row.payment_id,
                            payment_method: sub_row.payment_method,
                            date: sub_row.date,
                            total: sub_row.total,
                        });
                    });
                    _data.push({
                        label: this.$t('daily_brief_report_invoices.total') + ` (${this.currency_name})`,
                        invoice_code: '',
                        customer_name: '',
                        added_by: '',
                        payment_id: '',
                        payment_method: '',
                        date: '',
                        total: row.total.sum_total,
                    });
                });
                _data.push(_footer);
                return _data;
            },

            // *****************************************---

            revenueInvoiceExport() {
                let _footer = {label: this.$t('revenue_invoices.total') + ` (${this.currency_name})`, total: this.details_total_sum};
                let _data = [];
                this.data_report_details.forEach((row) => {
                    _data.push({
                        label: row.label,
                        // id : '',
                        customer_name: '',
                        added_by: '',
                        paid: '',
                        unpaid: '',
                        refund: '',
                        date: '',
                        total: '',
                    });
                    row.details.forEach((sub_row) => {
                        _data.push({
                            label: sub_row.id,
                            // id : sub_row.id,
                            customer_name: sub_row.customer_name,
                            added_by: sub_row.added_by,
                            paid: sub_row.paid,
                            refund: sub_row.refund,
                            unpaid: sub_row.unpaid,
                            date: sub_row.date,
                            total: sub_row.total,
                        });
                    });
                    _data.push({
                        label: this.$t('revenue_invoices.total') + ` (${this.currency_name})`,
                        customer_name: '',
                        added_by: '',
                        paid: row.total.sum_paid,
                        refund: row.total.sum_refund,
                        unpaid: row.total.sum_unpaid,
                        date: '',
                        total: row.total.sum_total,
                    });
                });
                _data.push(_footer);

                return _data;
            },

            salesInvoiceExport() {
                this.setReportLog('excel', 'daily brief report invoices');
                let _footer = {label: this.$t('revenue_invoices.total') + ` (${this.currency_name})`, total: this.details_total_sum};
                let _data = [];
                this.data_report_details.forEach((row) => {
                    _data.push({
                        label: row.label,
                        // id : '',
                        invoice_code: '',
                        customer_name: '',
                        added_by: '',
                        payment_method: '',
                        payment_id: '',
                        date: '',
                        total: '',
                    });
                    row.details.forEach((sub_row) => {
                        _data.push({
                            label: sub_row.id,
                            // id : sub_row.id,
                            invoice_code: sub_row.invoice_code,
                            customer_name: sub_row.customer_name,
                            added_by: sub_row.added_by,
                            payment_method: sub_row.payment_method,
                            payment_id: sub_row.payment_id,
                            date: sub_row.date,
                            total: sub_row.total,
                        });
                    });
                    _data.push({
                        label: this.$t('revenue_invoices.total') + ` (${this.currency_name})`,
                        invoice_code: '',
                        customer_name: '',
                        added_by: '',
                        payment_method: '',
                        payment_id: '',
                        date: '',
                        total: row.total.sum_total,
                    });
                });
                _data.push(_footer);

                return _data;
            },

            getRandom() {
                return Math.floor(Math.random() * 10000);
            },

            // fnExcelReport() {
            //     let table = document.getElementById('revenue_invoice');
            //     TableToExcel.convert(table, { // html code may contain multiple tables so here we are refering to 1st table tag
            //         name: this.$t('revenue_invoice.xlsx'), // fileName you could use any name
            //         sheet: {
            //             name: 'Sheet 1' // sheetName
            //         }
            //     });
            // },
            doFilter() {
                this.getLineChartInvoiceSummary();
                this.getPaidUnpaidInvoiceSummary();
                this.getReportInvoiceSummary();

                this.getLineChartInvoiceDetails();
                this.getPaidUnpaidInvoiceDetails();
                this.getReportInvoiceDetails();

                if (this.filters.filter_by) {
                    this.getLineChartInvoiceDetailsByFilter();
                    this.getPaidUnpaidInvoiceDetailsByFilter();
                    this.getReportInvoiceDetailsByFilter();
                }
            },
            resetFilter() {
                this.filters.customer_id = null;
                this.filters.from_date = null;
                this.filters.to_date = null;
                this.filters.user_id = null;
                this.filters.status = null;
                this.filters.filter_by = null;
                this.customer = null;
                this.user = null,

                this.doFilter();
            },

            getStatusList() {
                ApiService.get(this.mainRouteDependency + "/status").then((response) => {
                    this.status_list = response.data.data;
                });
            },

            getCustomers() {
                ApiService.get(this.mainRouteDependency + "/customers").then((response) => {
                    this.customers = response.data.data;
                });
            },
            getUsers() {
                ApiService.get(this.mainRouteDependency + "/users").then((response) => {
                    this.users = response.data.data;
                });
            },
            getAuthUser() {
                ApiService.get(`/get_auth`).then((response) => {
                    this.currency_name = response.data.data.currency_name;
                });
            },
            /*****
             * summary tab action
             */
            getLineChartInvoiceSummary() {
                ApiService.get(`${this.mainRoute}/invoice/report_invoice`, {params: {...this.filters}}).then((response) => {
                    this.line_invoice = response.data;
                });
            },
            getPaidUnpaidInvoiceSummary() {
                ApiService.get(`${this.mainRoute}/invoice/report_invoice_donuts`, {params: {...this.filters}}).then((response) => {
                    this.paid_unpaid = response.data.data;
                });
            },
            getReportInvoiceSummary() {
                ApiService.get(`${this.mainRoute}/invoice/report_invoice_list`, {params: {...this.filters}}).then((response) => {
                    this.data_report = response.data.data;
                    let _total_sum = this.$_.sumBy(response.data.data, (row) => {
                        return row.total ? parseFloat(row.total) : 0;
                    });
                    this.total_sum = _total_sum.toFixed(2);
                });
            },

            /*****
             * details tab action
             */
            getLineChartInvoiceDetails() {
                ApiService.get(`${this.mainRoute}/invoice/report_invoice`, {params: {...this.filters}}).then((response) => {
                    this.line_invoice_details = response.data;
                });
            },
            getPaidUnpaidInvoiceDetails() {
                ApiService.get(`${this.mainRoute}/invoice/report_invoice_donuts`, {params: {...this.filters}}).then((response) => {
                    this.paid_unpaid_details = response.data.data;
                });
            },
            getReportInvoiceDetails() {
                ApiService.get(`${this.mainRoute}/invoice/report_invoice_details`, {params: {...this.filters}}).then((response) => {
                    this.data_report_details = response.data.data;
                    let _total_sum = this.$_.sumBy(response.data.data, (row) => {
                        return row.total.sum_total ? parseFloat(row.total.sum_total) : 0;
                    });
                    this.details_total_sum = _total_sum.toFixed(2);
                    this.isDisabled = false;
                });
                // this.data_report_details =  [
                //     {   label:"2022-08-01",
                //         details: [
                //             {"customer_name":'asad', "added_by":'ahmed', "total": 100, "paid":12, "unpaid":0, "refund": 12 },
                //             {"customer_name":'asad', "added_by":'ahmed', "total": 100, "paid":12, "unpaid":0, "refund": 12 },
                //         ],
                //         total: {"sum_total": 200, "sum_paid":24, "sum_unpaid":0, "sum_refund": 24 },
                //     },
                //     {   label:"2022-08-02",
                //         details: [
                //             {"customer_name":'asad', "added_by":'ahmed', "total": 100, "paid":12, "unpaid":0, "refund": 12 },
                //             {"customer_name":'asad', "added_by":'ahmed', "total": 100, "paid":12, "unpaid":0, "refund": 12 },
                //         ],
                //         total: {"sum_total": 200, "sum_paid":24, "sum_unpaid":0, "sum_refund": 24 },
                //     },
                // ];
            },


            /*****
             * details by filter tab action
             */
            getLineChartInvoiceDetailsByFilter() {
                ApiService.get(`${this.mainRoute}/invoice/report_invoice_by_filter`, {params: {...this.filters}}).then((response) => {
                    this.line_invoice_details_by_filter = response.data;
                });
            },
            getPaidUnpaidInvoiceDetailsByFilter() {
                ApiService.get(`${this.mainRoute}/invoice/report_invoice_donuts_by_filter`, {params: {...this.filters}}).then((response) => {
                    this.paid_unpaid_details_by_filter = response.data.data;
                });
            },
            getReportInvoiceDetailsByFilter() {
                ApiService.get(`${this.mainRoute}/invoice/report_invoice_details_by_filter`, {params: {...this.filters}}).then((response) => {
                    this.data_report_details_by_filter = response.data.data;
                    let _total_sum = this.$_.sumBy(response.data.data, (row) => {
                        return row.total.sum_total ? parseFloat(row.total.sum_total) : 0;
                    });
                    this.details_total_sum_by_filter = _total_sum.toFixed(2);
                });
                // this.data_report_details =  [
                //     {   label:"2022-08-01",
                //         details: [
                //             {"customer_name":'asad', "added_by":'ahmed', "total": 100, "paid":12, "unpaid":0, "refund": 12 },
                //             {"customer_name":'asad', "added_by":'ahmed', "total": 100, "paid":12, "unpaid":0, "refund": 12 },
                //         ],
                //         total: {"sum_total": 200, "sum_paid":24, "sum_unpaid":0, "sum_refund": 24 },
                //     },
                //     {   label:"2022-08-02",
                //         details: [
                //             {"customer_name":'asad', "added_by":'ahmed', "total": 100, "paid":12, "unpaid":0, "refund": 12 },
                //             {"customer_name":'asad', "added_by":'ahmed', "total": 100, "paid":12, "unpaid":0, "refund": 12 },
                //         ],
                //         total: {"sum_total": 200, "sum_paid":24, "sum_unpaid":0, "sum_refund": 24 },
                //     },
                // ];
            },
            /**
             * register log
             */
            setReportLog(key, description){
                ApiService.post("reports/system_activity_log/log_type",{
                    key:key,
                    description: description,
                });
            }
        },
    };
</script>
